const wallpapers = {
    title: 'Wallpapers',
    header: 'Waves Wallpaper Pack',
    description:
        "A collection of 10 wallpapers designed to elevate your iPhone Lock Screen. Whether you're looking for a bold or understated design, this wallpaper pack has something for everyone.",
    download: 'Download',
    wallpaper01: 'Lagoon',
    wallpaper02: 'Aura',
    wallpaper03: 'Sandstone',
    wallpaper04: 'Oceanic',
    wallpaper05: 'Forest',
}

export default wallpapers
