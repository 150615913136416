const services = {
    title: 'Services',
    learnMore: 'En savoir plus',
    description:
        "Entrons dans le vif du sujet. Je travaille à la création de sites web orientés sur l'expérience de 1'utilisateur, afin que les entreprises puissent se développer.",
    webUIUXTitle: 'Web et UI/UX',
    webUIUXDescription:
        "Qu'il s'agisse de remanier ou construire complètement le site de votre marque, je travaille de façon à développer une plateforme qui répond à vos besoins. L'objectif ultime est d'optimiser votre entreprise, quel que soit le secteur d'activité.",
    yourSiteTitle: 'Votre Site',
    yourSiteDescription:
        "Votre site web est-il aussi transparent que possible? Il est essentiel pour vous d'offrir une plateforme facile d'utilisation qui priorise l'accès à vos produits et services, sans en compromettre le design. Cela favorise une expérience conviviale, quel que soit l'appareil utilisé.",
    workingWithMeTitle: 'Travailler avec moi',
    workingWithMeDescription:
        'En commençant par discuter des besoins de votre entreprise, nous déterminons les étapes nécessaires à la mise en oeuvre de vos idées. Je vous accompagne également tout an long du processus afin de vous tenir an courant de son évolution, tout en répondant à vos questions.',
    processStep1Title: 'Regroupement des exigences requises',
    processStep1Description:
        "Nous commençons par établir une image générale de la plate-forme, même si vous ne partez que d'une brève description de votre vision. Puis, je vous aide à étoffer vos idées, et à regrouper les spécifications et exigences requises.",
    processStep2Title: 'Design UX',
    processStep2Description:
        "La conception d'un système de design débute ici. Cette étape de création se combine d'aver une approche centrée sur l'expérience de l'utilisateur. Je vous enverrai alors des rendus de ce à quoi ressemblera votre site.",
    processStep3Title: 'Développement',
    processStep3Description:
        "Tous les détails techniques sont traits, et chaque element de 1'interface utilisateur est conçu. Des tests approfondis sont également exécutés pour garantir la qualité de la plate-forme dans son ensemble.",
    processStep4Title: 'Lancement',
    processStep4Description:
        'Le lancement de votre plate-forme a lieu auprès de vos clients potentiels. Ils découvrent une interface intuitive et adaptée à leurs multiples appareils, et qui retranscrit votre image de marque.',
}

export default services
