const services = {
    title: 'Services',
    learnMore: 'Learn more',
    description:
        "Let's dive right in. I build user-friendly interfaces based on brands' needs to allow them to scale their businesses.",
    webUIUXTitle: 'Web & UI/UX',
    webUIUXDescription:
        "I work towards developing a platform tailored to your needs, whether by revamping or building your brand's website from scratch. The goal is to ultimately improve your business, no matter the industry.",
    yourSiteTitle: 'Your Site',
    yourSiteDescription:
        "Is your website as seamless as it can be? It's essential for you to offer an easy to browse website that prioritizes access to your products & services, without compromising the design. This enables a user-friendly experience, no matter the device used.",
    workingWithMeTitle: 'Working with me',
    workingWithMeDescription:
        'We start with a conversation around your business needs. This allows me to look into the steps required to implement your ideas, while also answering any questions you may have. I also walk you through every step of the process so you can stay up to date with its development.',
    processStep1Title: 'Requirement Gathering',
    processStep1Description:
        "We start by establishing a broad picture of the platform, even if we're starting off from a brief description of your vision in general terms. I'll help you flesh out your ideas and write up your detailed specs and requirements.",
    processStep2Title: 'UX Design',
    processStep2Description:
        "The creation of a design system begins here. This creative stage is combined with a user experience focused approach. Accordingly, I'll send you mockups of what your platform will end up looking like.",
    processStep3Title: 'Development',
    processStep3Description:
        'All technical details are dealt with and each component of the user interface is implemented. Thorough testing also takes place to ensure the quality of the platform as a whole.',
    processStep4Title: 'Launch Date',
    processStep4Description:
        "Your platform launches and is accessible to your target audience. As a result, they're faced with an intuitive & mobile-friendly user interface, which resonates with your brand's identity.",
}

export default services
