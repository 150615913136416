const contact = {
    title: 'Contact',
    header: 'Reach out \nto me for any enquiries',
    contactMe: 'Contact me',
    haveQuestions: 'I have questions',
    getAQuote: 'Get a quote',
    description:
        "Tell me more about your business goals and ideas! Let's start a conversation about your vision for your website.",
    paragraph:
        "Thinking about starting or scaling your business? Let's talk about your web platform! I'm an email away.",
    name: 'Name',
    email: 'E-mail',
    company: 'Company',
    message: 'Message',
    send: 'Send',
}

export default contact
