import homepage from './homepage'
import about from './about'
import projects from './projects'
import services from './services'
import contact from './contact'
import error404 from './error404'
import thankYou from './thank-you'
import socialMedia from './socialMedia'
import miscellaneous from './miscellaneous'
import wallpapers from './wallpapers'

const fr = {
    homepage,
    about,
    projects,
    services,
    contact,
    error404,
    thankYou,
    socialMedia,
    miscellaneous,
    wallpapers,
}

export default fr
