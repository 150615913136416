const projects = {
    title: 'Projets',
    viewWork: 'Portfolio',
    viewAll: 'Voir tout',
    pastWork: 'Projets',
    description:
        "Jetez un coup d'oeil à mon portfolio pour avoir un aperçu de la diversité de mon travail.",
}

export default projects
