const contact = {
    title: 'Me Rejoindre',
    header: 'Contactez-moi \npour tout renseignements',
    contactMe: 'Me Rejoindre',
    haveQuestions: "J'ai des questions",
    getAQuote: 'Obtenir une soumission',
    description:
        'Parlez-moi de vos objectifs et idées concernant votre entreprise! Commençons par discuter de votre vision pour votre site web.',
    paragraph:
        'Vous envisagez de créer ou développer votre entreprise? Envoyez-moi un courriel et parlons de votre site web!',
    name: 'Nom',
    email: 'Courriel',
    company: 'Compagnie',
    message: 'Message',
    send: 'Soumettre',
}

export default contact
