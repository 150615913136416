const wallpapers = {
    title: "Fonds d'écran",
    header: "Fonds d'écran - Vagues",
    description:
        "Une collection de 10 fonds d'écran conçus pour élever iPhone. Que vous soyez à la recherche d'un design audacieux ou discret, ce pack de fonds d'écran a quelque chose pour tout le monde.",
    download: 'Télécharger',
    wallpaper01: 'Lagoon',
    wallpaper02: 'Aura',
    wallpaper03: 'Sandstone',
    wallpaper04: 'Oceanic',
    wallpaper05: 'Forest',
}

export default wallpapers
